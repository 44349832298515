<script>
  export let config = {
      haschats : true,
      show : false,
      helloText : "Halo, ada yang bs kami bantu?",
      chatText : "Saya Mau Pesan",
      phone : "",
      title : ""
  };

  export let prod = false;
</script>
<div   class="{prod ? 'fixed right-5 bottom-5 lg:right-10 lg:bottom:10' : 'flex justify-end'}">
<div  class="flex">
   {#if config.haschats && config.show}
   
   <div class="w-64 bg-white mr-3 max-w-full  ">
       <div class="h-12 bg-{config.color_scheme} flex p-2 text-white  ">
        <div class="mr-2">
          <img class="h-8 w-8 rounded-full" src="{config.avatar}"   alt="">
        </div>
        <div class="pt-1 ">
          {config.title} 
        </div>
        <div class="pt-2 ml-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
       </div>
       <div class="  border-l border-r border-gray-50 py-6  p-3 text-sm">
         {config.helloText}
       </div>
       <div class=" ">
         <div>
 
           <div class="  flex p-2  bg-gray-50">
             <div class="relative flex w-full">
 
               <input type="text" bind:value="{config.chatText}" class="focus:outline-none rounded-l border border-gray-200  text-sm  px-4 w-full" placeholder="Pesan">
             </div>
             <a target="_blank"  href="https://wa.me/{config.phone}?text={config.chatText}" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2   text-sm font-medium  text-gray-500 bg-{config.color_scheme} rounded-r focus:outline-none  ">
               <!-- Heroicon name: solid/sort-ascending -->
 
               <span><svg class="text-white" style="width:24px;height:24px" viewBox="0 0 24 24">
                   <path fill="currentColor" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                 </svg></span>
             </a>
           </div>
         </div>
       </div>
     </div>
   {/if}

 <div class="flex items-end">
   {#if config.haschats  }
   <button  on:click="{()=>{config.show = !config.show}}" type="button" class="inline-flex items-center {config.size == 'small' ? 'p-2' : 'p-3'}  border border-transparent rounded-full shadow-sm text-white bg-{config.color_scheme}   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
     <!-- Heroicon name: outline/plus-sm -->
     {#if  config.show}
     <svg  class="{config.size == 'small' ? 'h-6 w-6' : 'h-7 w-7'} " viewBox="0 0 24 24">
       <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
     </svg>
     {/if}
     {#if  !config.show}
     <svg    class="{config.size == 'small' ? 'h-6 w-6' : 'h-7 w-7'} " viewBox="0 0 24 24">
       <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
     </svg> 
     {/if}
    
     
   </button>
   {:else}
   <a target="_blank" href="https://wa.me/{config.phone}?text={config.chatText}" class="inline-flex items-center {config.size == 'small' ? 'p-2' : 'p-3'} border border-transparent rounded-full shadow-sm text-white bg-{config.color_scheme} hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
       <svg   class="{config.size == 'small' ? 'h-6 w-6' : 'h-7 w-7'} " viewBox="0 0 24 24">
           <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
         </svg>
   </a>
   {/if}
 </div>
</div>

</div>

<div class='bg-gray-400'></div><div class='bg-gray-500'></div><div class='bg-gray-600'></div><div class='bg-gray-700'></div><div class='bg-gray-800'></div><div class='bg-gray-900'></div><div class='bg-red-400'></div><div class='bg-red-500'></div><div class='bg-red-600'></div><div class='bg-red-700'></div><div class='bg-red-800'></div><div class='bg-red-900'></div><div class='bg-yellow-400'></div><div class='bg-yellow-500'></div><div class='bg-yellow-600'></div><div class='bg-yellow-700'></div><div class='bg-yellow-800'></div><div class='bg-yellow-900'></div><div class='bg-green-400'></div><div class='bg-green-500'></div><div class='bg-green-600'></div><div class='bg-green-700'></div><div class='bg-green-800'></div><div class='bg-green-900'></div><div class='bg-blue-400'></div><div class='bg-blue-500'></div><div class='bg-blue-600'></div><div class='bg-blue-700'></div><div class='bg-blue-800'></div><div class='bg-blue-900'></div><div class='bg-indigo-400'></div><div class='bg-indigo-500'></div><div class='bg-indigo-600'></div><div class='bg-indigo-700'></div><div class='bg-indigo-800'></div><div class='bg-indigo-900'></div><div class='bg-purple-400'></div><div class='bg-purple-500'></div><div class='bg-purple-600'></div><div class='bg-purple-700'></div><div class='bg-purple-800'></div><div class='bg-purple-900'></div><div class='bg-pink-400'></div><div class='bg-pink-500'></div><div class='bg-pink-600'></div><div class='bg-pink-700'></div><div class='bg-pink-800'></div><div class='bg-pink-900'></div>
<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
