import App from './App.svelte';

const app = new App({
	target: document.body,
	props : {
		config : window.wa_button_config,
		prod : true
	}
});

export default app;

